// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classics-js": () => import("./../src/pages/classics.js" /* webpackChunkName: "component---src-pages-classics-js" */),
  "component---src-pages-community-js": () => import("./../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-favourites-js": () => import("./../src/pages/favourites.js" /* webpackChunkName: "component---src-pages-favourites-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myrecipes-js": () => import("./../src/pages/myrecipes.js" /* webpackChunkName: "component---src-pages-myrecipes-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-random-js": () => import("./../src/pages/random.js" /* webpackChunkName: "component---src-pages-random-js" */),
  "component---src-pages-recipe-js": () => import("./../src/pages/recipe.js" /* webpackChunkName: "component---src-pages-recipe-js" */),
  "component---src-pages-signedin-js": () => import("./../src/pages/signedin.js" /* webpackChunkName: "component---src-pages-signedin-js" */),
  "component---src-pages-signedout-js": () => import("./../src/pages/signedout.js" /* webpackChunkName: "component---src-pages-signedout-js" */),
  "component---src-pages-welcome-js": () => import("./../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

